<template>
	<div
		class="mobile-event"
		:class="{
			trs: trs,
			'padding-off': paddingOff,
		}"
		:style="{
			height: height,
		}"
	>
		<div
			class="top-mobile-group"
			@click="opened ? removeOpenEl($event) : addOpenEl($event)"
			:class="checkWidth(data.name)"
			ref="test"
		>
			<div class="mobile-date">
				<div class="date">
					{{ data.date.mDate }}
				</div>
				<div class="weekday">
					{{ data.date.maxWeek }}
				</div>
				<div
					v-if="data.sticker"
					class="sticker"
					:style="{
						background: data.sticker.color,
					}"
				>
					{{ data.sticker.message }}
				</div>
			</div>
			<div class="mobile-artist">
				{{ data.name }}
			</div>
			<svg viewBox="0 0 99 84" fill="none">
				<path
					d="M56.7998 0L98.7998 42L56.7998 84H53.0198L93.7598 43.26H0.799805V40.74H93.7598L53.0198 0H56.7998Z"
					fill="white"
				/>
			</svg>
		</div>
		<div class="mobile-hover-group">
			<div class="mobile-description">
				{{ data.summary }}
			</div>
			<div class="tags">
				<div v-for="(t, tt) in data.tags" :key="tt" class="tag">
					{{ t }}
				</div>
			</div>
			<div class="mobile-age">{{ data.minAge }}+</div>
			<button
				@click="
					$router.push(
						galleryPage ? '/past' + data.url + '/true' : data.url
					)
				"
				class="buy-tickets main-t"
			>
				<img
					v-if="!galleryPage"
					src="/static/icons/ticket.svg"
					alt=""
				/>
				{{
					galleryPage ? $t("checkPhoto") : btnText(data.minPrice).str
				}}
			</button>
		</div>
	</div>
</template>

<script>
import parseDateMixin from "./parseDateMixin";
export default {
	mixins: [parseDateMixin],
	props: {
		data: {
			default: () => ({
				name: "loremipsum true loremipsum true",
				description: "Lorem ipsum dolor sit, amet",
				age: 18,
				date: "",
				link: "/name",
			}),
		},
		galleryPage: {
			default: false,
		},
		paddingOff: {
			default: false,
		},
	},
	data() {
		return {
			opened: false,
			openHeight: 0,
			height: 0,
			trs: false,
		};
	},
	methods: {
		addOpenEl(e) {
			console.log(e);
			const t = e.target.closest(".mobile-event");
			const height =
				t.querySelector(".top-mobile-group").scrollHeight +
				t.querySelector(".mobile-hover-group").scrollHeight;
			t.classList.add("hovered");
			this.opened = true;
			t.style.height = height + 50 + "px";
		},
		checkWidth(word) {
			let className = "";
			for (let wd of word.split(" ")) {
				if (wd.length > 20) className += " fadeName";
				if (wd.length > 11) className += " tooBig";
			}
			return className;
		},
		removeOpenEl(e) {
			const t = e.target.closest(".mobile-event");
			t.classList.remove("hovered");
			this.opened = false;
			t.style.height = this.openHeight;
		},
	},
	mounted() {
		this.openHeight =
			this.$refs.test.childNodes[1].offsetHeight + 42 + "px";
		this.height = this.openHeight;
		setTimeout(() => {
			this.trs = true;
		}, 10);
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.mobile-event {
	padding: ptr(6px) ptr(10px) ptr(16px);
	box-sizing: border-box;
	position: relative;
	min-height: ptr(100px);
	overflow: hidden;
	&::after {
		content: "";
		left: ptr(10px);
		width: calc(100% - 20rem / 16);
		height: 1px;
		position: absolute;
		bottom: 0;
		background: rgba(255, 255, 255, 0.26);
	}
}
.padding-off {
	padding: ptr(16px) 0;
	&::after {
		left: 0;
		width: 100%;
	}
}
.top-mobile-group {
	display: grid;
	align-items: center;
	grid-template-columns: ptr(56px) 1fr ptr(16px);
	gap: ptr(14px);
	svg {
		width: ptr(16px);
		transform: rotate(90deg);
		transition: $trs;
		align-self: flex-start;
		margin-top: ptr(17px);
		opacity: 1;
	}
}
.mobile-date {
	align-self: flex-start;
	padding-top: ptr(10px);
	.date {
		font-size: ptr(18px);
		font-weight: 500;
	}
	.weekday {
		font-size: ptr(7px);
		font-weight: 500;
	}
}
.mobile-artist {
	font-size: ptr(30px);
	font-weight: 300;
	text-transform: uppercase;
	margin: 0;
	display: flex;
	align-items: flex-start;
	padding-top: ptr(10px);
	line-height: ptr(29px);
	min-height: ptr(46px);
}
.tooBig {
	.mobile-artist {
		font-size: ptr(21px);
	}
}
.fadeName {
	overflow: hidden;
	.mobile-artist {
		display: block;
		max-width: 200px;
		background-image: linear-gradient(
			90deg,
			#ffffff 90%,
			rgba(0, 0, 0, 0) 100%
		);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
.sticker {
	position: absolute;
	left: ptr(0px);
	background: $sub-color;
	display: flex;
	align-items: center;
	font-size: ptr(6px);
	text-transform: uppercase;
	padding: ptr(6px) ptr(10px) ptr(3px) ptr(10px);
	margin-top: ptr(5px);
	border-radius: 0 ptr(100px) ptr(100px) 0;
	max-width: ptr(40px);
}
.mobile-hover-group {
	width: 100%;
	opacity: 0;
	transition: $trs;
	display: grid;
	grid-template-columns: ptr(56px) 1fr;
	margin-top: ptr(14px);
	gap: ptr(21px) ptr(14px);
	.mobile-description {
		font-size: ptr(12px);
		grid-column: 2;
		font-weight: 300;
	}
}
.mobile-age {
	align-self: center;
	font-size: ptr(14px);
	font-weight: 300;
	grid-row: 3;
}
.trs {
	transition: $trs;
}
.tags {
	grid-row: 2;
	grid-column: 2;
	position: relative;
	top: 0;
	opacity: 0.6;
}
.tag {
	font-size: ptr(12px);
	padding: ptr(3px) ptr(8px);
}
.hovered {
	.top-mobile-group svg {
		opacity: 1;
		transform: rotate(-90deg);
	}
	.mobile-hover-group {
		opacity: 1;
	}
}
</style>
