<template>
	<section>
		<picture>
			<source
				srcset="/static/img/main-back.jpg"
				media="(max-width: 1024px)"
			/>
			<source srcset="/static/img/main-back.png" />
			<img src="/static/img/main-back.png" alt="" class="fixed-img" />
		</picture>
		<div class="header-info">
			<h1>{{ $t("passedEvents") }}</h1>
			<div class="type-selector">
				<button @click="listType = true" :class="{ chosen: listType }">
					{{ $t("typeView1") }}
				</button>
				<button
					@click="listType = false"
					:class="{ chosen: !listType }"
				>
					{{ $t("typeView2") }}
				</button>
			</div>
		</div>
		<article class="months-schedule">
			<div v-for="(m, k) in months" :key="k" class="month">
				<div class="month-header">
					{{ m.year }}
				</div>
				<div class="month-mass" :class="{ 'table-type': !listType }">
					<template v-for="(e, kk) in m.events">
						<div
							v-if="!isMobile"
							:key="kk"
							class="event-container"
							:class="{ 'fade-name': e.name.length > 20 }"
						>
							<div class="event-data">
								<div class="number">
									{{ e.date.mDate }}
								</div>
								<span>
									{{ e.date.maxWeek }}
								</span>
							</div>
							<div class="event-info">
								<div class="tags">
									<div
										v-for="(t, index) in e.tags"
										:key="index"
										class="tag"
									>
										{{ t }}
									</div>
								</div>
								<router-link
									:to="`/past${e.url}`"
									class="artist-name"
								>
									{{ e.name }}
								</router-link>
								<div class="event-description">
									{{ e.summary }}
								</div>
							</div>
							<div class="event-age">{{ e.minAge }}+</div>
							<button
								@click="$router.push('/past' + e.url + '/true')"
								class="buy-tickets"
							>
								<img src="/static/icons/camera.svg" alt="" />
								{{ $t("checkPhoto") }}
							</button>
						</div>
						<mobile-event-component
							v-else
							:galleryPage="true"
							:key="kk"
							:data="e"
						/>
					</template>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
import MobileEventComponent from "./MobileEventComponent.vue";
import { http } from "../components/services/httpService";
import parseDateMixin from "./parseDateMixin";
export default {
	mixins: [parseDateMixin],
	components: { MobileEventComponent },
	props: {
		isMobile: {
			default: false,
		},
	},
	data() {
		return {
			listType: true,
			months: [
				{
					month: "",
					year: 0,
					events: [],
				},
			],
			// events: [],
		};
	},
	methods: {
		getPoster() {
			http.get(`/api/events/past?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.months = response.data.eventGroups.map(
							(m) =>
								(m = {
									...m,
									events: m.events.map((e) => ({
										...e,
										date: this.parseDate(e.date),
									})),
								})
						);
					}
				}
			);
		},
	},
	watch: {
		"$route.params"() {
			document.documentElement.scrollTop = 0;
		},
		"$route.name"(newVal) {
			if (newVal === "passedEvents") {
				document.title = this.$t("passedEvents");
			}
		},
		"$i18n.locale": function() {
			this.getPoster();
		},
	},
	mounted() {
		this.getPoster();
		document.title = this.$t("passedEvents");
		document.documentElement.scrollTop = 0;
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/event.scss";
@import "./styles/poster.scss";

section {
	padding-right: ptr(58px);
	user-select: none;
}

.header-info {
	display: flex;
	align-items: flex-start;
	h1 {
		margin-left: ptr(-9px);
	}
}

.buy-tickets {
	grid-column: 4;
}

@media (max-width: 1024px) {
	section {
		padding-right: 0;
	}
	.header-info {
		margin: ptr(20px) 0;
		h1 {
			font-size: ptr(40px);
			font-weight: 300;
			margin: 0;
		}
	}
	.gallery-container {
		margin: ptr(20px) 0 0 ptr(12px);
	}
	.image {
		width: ptr(300px);
	}
}
</style>
