import Vue from "vue";
import VueRouter from "vue-router";
import MainPage from "@/views/MainPage";
import PosterPage from "@/views/PosterPage";
import EventPage from "@/views/EventPage";
import PastEventPage from "@/views/PastEventPage";
import ContactsPage from "@/views/ContactsPage";
import GalleryPage from "@/views/GalleryPage";
import PastEvents from "@/views/PastEvents";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Main",
		component: MainPage,
	},
	{
		path: "/poster",
		name: "poster",
		component: PosterPage,
	},
	{
		path: "/gallery",
		name: "gallery",
		component: GalleryPage,
	},
	{
		path: "/passedEvents",
		name: "passedEvents",
		component: PastEvents,
	},
	{
		path: "/contacts",
		name: "contacts",
		component: ContactsPage,
	},
	{
		path: "/past/:name/:scroll?",
		name: "PastEvent",
		component: PastEventPage,
	},
	{
		path: "/:name",
		name: "Event",
		component: EventPage,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
