import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from "./components/services/i18n";
import VueYandexMetrika from "./components/metrika";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

if (process.env.NODE_ENV === "production") {
  Vue.use(VueGtag, {
    config: { id: "UA-192162988-1" }
  }, router);

  Vue.use(VueYandexMetrika, {
    id: 73881163,
    router: router,
    env: process.env.NODE_ENV,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  });
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
