var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_c('div',{staticClass:"line-container"},[_c('div',{staticClass:"event-place-description-pages",on:{"touchmove":_vm.clearInt}},[_vm._l((_vm.mass),function(h,k){return _c('div',{key:k,ref:"topTxt",refInFor:true,staticClass:"pages-header",class:{
					chosen: _vm.sliderOptions.page === k,
				},on:{"click":function($event){return _vm.setSliderPage(k)}}},[_vm._v(" "+_vm._s(h.name)+" ")])}),_c('div',{staticClass:"active-line",style:({
					left: _vm.sliderOptions.line.offset + 'px',
					width: _vm.sliderOptions.line.width + 'px',
				})})],2)]),_c('div',{staticClass:"event-place-pages-content"},[_c('div',{staticClass:"corousel-body",on:{"touchmove":_vm.moving,"touchend":_vm.moveEnd}},[_c('div',{staticClass:"slider-component",class:{
					'full-mode': _vm.fullMode,
				},style:({
					transform: ("translate3d(" + (_vm.sliderOptions.translate.current) + "px,0,0)"),
					'transition-duration': _vm.sliderOptions.translate.move
						? '0s'
						: '.4s',
				})},_vm._l((_vm.mass),function(h,k){return _c('div',{key:k,staticClass:"event-place-content",class:{ inactive: _vm.sliderOptions.page !== k },style:({
						width: _vm.slideWidth + 'px',
					})},[_vm._t("default",null,{"content":h})],2)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }