<template>
	<section class="gallery">
		<div class="header-info" :class="{ hide }">
			<h1>{{ $t("gallery") }}</h1>
		</div>
		<article>
			<gallery
				video=""
				:images="mediaBlock"
				:grlPage="true"
				@changePage="
					currentPage = $event;
					hide = true;
				"
			/>
			<div class="points">
				{{ currentPage }} / {{ mediaBlock.length }}
			</div>
		</article>
	</section>
</template>

<script>
import Gallery from "../components/Gallery.vue";
import { http } from "../components/services/httpService";
export default {
	components: { Gallery },
	props: {
		isMobile: {
			default: false,
		},
	},
	data() {
		return {
			listType: true,
			mediaBlock: [],
			hide: false,
			currentPage: 1,
		};
	},
	methods: {
		getGallery() {
			http.get("/api/gallery").then((response) => {
				this.mediaBlock = response.data.images;
			});
		},
		isActvie(k) {
			if (this.currentPage === this.mediaBlock.length && k === 7) {
				return 7;
			}
			if (this.currentPage > 6) {
				return 6;
			}
			return this.currentPage === k ? k : 0;
		},
	},
	watch: {
		"$route.params"() {
			document.documentElement.scrollTop = 0;
		},
		"$route.name"(newVal) {
			if (newVal === "gallery") {
				document.title = this.$t("gallery");
			}
		},
		"$i18n.locale": function() {
			this.getPoster();
		},
	},
	mounted() {
		this.getGallery();
		document.title = this.$t("gallery");
		document.documentElement.scrollTop = 0;
	},
};
</script>

<style lang="scss">
@import "../components/styles/config.scss";
.gallery {
	.image {
		width: 100vw;
		height: 100vh;
		transition: $trs;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.multiple-corousel {
		transition: none;
		padding: 0;
	}
	.inactive {
		opacity: 0;
	}
	.arrow {
		width: 40vw;
		height: 100vh;
		cursor: url("/static/icons/arrw.svg"), auto;
		&::after {
			content: none;
		}
		svg {
			opacity: 0;
		}
	}
	.left {
		left: ptr(220px);
		cursor: url("/static/icons/arrwl.svg"), auto;
	}
}
@media (max-width: 1024px) {
	.gallery {
		.image {
			width: 100vw;
			height: ptr(300px);
		}
		.inactive {
			opacity: 1;
		}
	}
}
</style>

<style lang="scss" scoped>
@import "./styles/event.scss";
@import "./styles/poster.scss";

section {
	padding-right: 0;
	user-select: none;
}

.header-info {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;
	h2 {
		font-size: ptr(74px);
		font-weight: 100;
		margin: 0;
		transition: $trs;
	}
}
.hide {
	opacity: 0;
	pointer-events: none;
}
.sub {
	margin: ptr(120px) 0 ptr(30px);
}
.gallery-container {
	top: ptr(-40px);
	left: ptr(-265px);
	margin: 0;
	position: absolute;
	width: 100vw;
	height: 100vh;
	&::before {
		content: "";
		height: 100vh;
		width: ptr(260px);
		z-index: 1;
		position: absolute;
		pointer-events: none;
		left: 0;
		top: 0;
		background: linear-gradient(90deg, black, rgba(0, 0, 0, 0));
	}
}

.buy-tickets {
	grid-column: 4;
}

.points {
	position: fixed;
	bottom: ptr(65px);
	z-index: 6;
	align-items: center;
	height: ptr(15px);
	font-size: ptr(16px);
}
.point {
	border-radius: 50%;
	width: ptr(10px);
	transition: $trs;
	height: ptr(10px);
	background: rgba(255, 255, 255, 0.5);
	&:nth-last-child(1) {
		width: ptr(5px);
		height: ptr(5px);
	}
}
.active {
	background: rgba(255, 255, 255, 1);
	width: ptr(15px);
	height: ptr(15px);
	&:nth-last-child(1) {
		opacity: 0;
	}
}

@media (max-width: 1024px) {
	section {
		padding: 0;
	}
	.gallery-container {
		position: static;
		width: 100vw;
		height: ptr(320px);
	}
	.header-info {
		margin-top: ptr(20px);
		h2 {
			font-size: ptr(34px);
			font-weight: 300;
			margin: 0;
		}
	}
	.gallery-container {
		margin: ptr(20px) 0 0 ptr(12px);
		&::before {
			content: none;
		}
	}
	.points {
		display: none;
	}
	.image {
		width: ptr(300px);
	}
}
</style>
