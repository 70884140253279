<template>
	<section>
		<h1>{{ $t("contacts") }}</h1>

    <div class="contacts-main">
      <div class="contacts-main__fio">Michael Nemykin</div>
      <div class="contacts-main__position">General Producer</div>
      <div class="contacts-main__email">mn@placebo25.com</div>
		</div>


    <div class="contacts-bottom">
      <div class="contacts-worldwide">WORLDWIDE</div>
      <div class="contacts-peoples">
        <div class="contacts-peoples__block" v-for="people in peoples" :key="people.name">
          <div class="contacts-peoples__name">{{ people.name }}</div>
          <div class="contacts-peoples__position">{{ people.position }}</div>
          <div class="contacts-peoples__address">{{ people.address }}</div>
          <div class="contacts-peoples__email">{{ people.email }}</div>
        </div>
      </div>
    </div>

    <img class="map" src="static/img/map.svg" alt="" />

	</section>
</template>

<script>
import { http } from "../components/services/httpService";
export default {
	data() {
		return {
			contacts: {
				city: "",
				address: "",
				email: "",
				phone: "",
				workTime: "10:00-19:00",
				partnersEmail: "",
				rentSite: "",
				map: {
					image: "/static/img/map.svg",
					url: "",
				},
				socials: {
					vk: "",
					instagram: "",
					facebook: "",
					telegram: "",
				},
			},
      peoples: [
          { name: "Karina Kanzafarova", position: "Booking Manager", address: "Bali, Jalan Dewi Saraswati 2", email: "kei@placebo25.com" },
          { name: "Gennady Shulman", position: "Tour Department Director", address: "Jerusalem, Ben Yehuda, 34", email: "gs@placebo25.com" },
          { name: "Luca Albano", position: "Booking Manager", address: "Berlin, Frank Zappa Strasse 11", email: "luca@placebo25.com" },
          { name: "JeanKarl Saliba", position: "Booking Manager", address: "Dubai, Tiara tower East", email: "entertainment@placebo25.com" },
          { name: "Ruslan Voskolei", position: "Booking Manager", address: "Oakville, L6M 3P1, ON, Canada", email: "rv@placebo25.com" },
          { name: "Irina Sherbakova", position: "Booking agency", address: "Leninskaya Sloboda 26, building 15", email: "entertainment@placebo25.com" },
      ]
		};
	},
	methods: {
		getContacts() {
			http.get(`/api/contacts?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.contacts = response.data;
						const t = this.contacts.socials.telegram.split("@")[1];
						this.contacts.socials.telegram = t;
					}
				}
			);
		},
	},
	watch: {
		"$i18n.locale": function() {
			this.getContacts();
		},
		"$route.name"(newVal) {
			if (newVal === "contacts") {
				document.title = this.$t("contacts");
			}
		},
	},
	mounted() {
		this.getContacts();
		document.title = this.$t("contacts");
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.contacts-main {
  margin-top: 48px;
  font-family: $main-font;
  font-style: normal;
  font-weight: 400;
  line-height: ptr(28px);
  z-index: 1;
}

.contacts-main__fio {
  font-size: ptr(26px);
  text-transform: uppercase;
  color: #FFFFFF;
}

.contacts-main__position {
  font-size: ptr(14px);
  color: #484848;
}

.contacts-main__email {
  font-size: ptr(16px);
  color: #9B2B2B;
}

.contacts-bottom {
  position: absolute;
  bottom: 60px;
  z-index: 1;
}

.contacts-worldwide {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: ptr(26px);
  line-height: ptr(28px);
  color: #505050;
}

.contacts-worldwide::after {
  content: "";
  position: absolute;
  width: ptr(493px);
  top: ptr(45px);
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #3F3F3F
}

.contacts-peoples {
  display: flex;
  flex-wrap: wrap;
  margin-top: ptr(40px);
  width: 700px;
  flex-direction: row;
}

.contacts-peoples__block {
  flex: 1 1 50%;
  margin-top: 24px;
  font-family: $main-font;
  font-style: normal;
  font-weight: 400;
}

.contacts-peoples__name {
  font-size: ptr(18px);
  text-transform: uppercase;
  color: #FFFFFF;
  line-height: ptr(28px);
}

.contacts-peoples__position {
  font-size: ptr(14px);
  color: #484848;
  line-height: ptr(21px);
}

.contacts-peoples__address {
  font-size: ptr(14px);
  color: #484848;
  line-height: ptr(21px);
}

.contacts-peoples__email {
  font-size: ptr(16px);
  color: #FFFFFF;
  line-height: ptr(28px);
}

section {
  position: fixed;
  display: grid;
  gap: ptr(45px);
	left: ptr(260px);
	top: ptr(39px);
	width: 100%;
  height: 90%;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
	margin-top: ptr(30px);
	h1 {
    font-style: normal;
    line-height: 112px;
		font-size: ptr(92px);
		font-weight: 100;
		margin: 0 0 0 ptr(-11px);
		transform: translateY(ptr(-15px));
	}
}

.map {
	position: absolute;
  top: 50%;
  transform: translateY(-50%);

	right: ptr(-30px);
	width: ptr(1280px);
	height: ptr(750px);
}
@media (max-width: 1024px) {
  .contacts-main {
    display: block;
    margin-top: ptr(10px);
  }
  .contacts-worldwide {
    display: block;
  }
  .contacts-worldwide::after {
    display: none;
  }
  .contacts-bottom {
    display: block;
    position: relative;
    margin-top: ptr(100px);
  }
  .contacts-peoples {
    flex-direction: column;
    margin-top: ptr(10px);
  }

  .contacts-peoples__name {
    font-size: ptr(16px);
  }
  .contacts-peoples__position {
    font-size: ptr(12px);
  }
  .contacts-peoples__address {
    font-size: ptr(12px);
  }
  .contacts-peoples__email {
    font-size: ptr(13px);
  }

	section {
    margin-left: ptr(10px);
    position: relative;
    display: block;
    left: inherit;
    top: ptr(39px);
    width: 100%;
    height: 90%;
    margin-top: ptr(-40px);
    h1 {
      font-style: normal;
      line-height: 112px;
      font-size: ptr(46px);
      font-weight: 100;
      margin: 0 0 0 ptr(-2px);
      transform: translateY(ptr(-15px));
    }
  }
	.map {
    display: block;
    position: relative;
    margin-top: ptr(20px);
    margin-bottom: ptr(40px);
		width: ptr(400px);
		height: ptr(320px);
    transform: translateX(-30%);
	}
}
</style>
