<template>
	<section>
		<picture>
			<source
				srcset="/static/img/main-back.jpg"
				media="(max-width: 1024px)"
			/>
			<source srcset="/static/img/main-back.png" />
			<img src="/static/img/main-back.png" alt="" class="fixed-img" />
		</picture>
		<div class="header-info">
			<h1>{{ $t("poster") }}</h1>
			<div class="type-selector">
				<button @click="listType = true" :class="{ chosen: listType }">
					{{ $t("typeView1") }}
				</button>
				<button
					@click="listType = false"
					:class="{ chosen: !listType }"
				>
					{{ $t("typeView2") }}
				</button>
			</div>
		</div>
		<article class="months-schedule">
			<div v-for="(m, k) in months" :key="k" class="month">
				<div class="month-header">
					{{ m.month }}
				</div>
				<div class="month-mass" :class="{ 'table-type': !listType }">
					<template v-for="(e, kk) in m.events">
						<div
							v-if="!isMobile"
							:key="kk"
							class="event-container"
							:class="{ 'fade-name': e.name.length > 20 }"
						>
							<div class="event-data">
								<div class="number">
									{{ e.date.mDate }}
								</div>
								<span>
									{{ e.date.maxWeek }}
								</span>
							</div>
							<div class="event-info">
								<div class="tags">
									<div
										v-for="(t, index) in e.tags"
										:key="index"
										class="tag"
									>
										{{ t }}
									</div>
								</div>
								<router-link
									:to="`${e.url}`"
									class="artist-name"
								>
									{{ e.name }}
								</router-link>
								<div class="event-description">
									{{ e.summary }}
								</div>
							</div>
							<div class="event-age">{{ e.minAge }}+</div>
							<button
								:disabled="btnText(e.minPrice).sold"
								@click="
									ticketsWidgetCall(
										e.ticketsWidget,
										btnText(e.minPrice).sold,
										e.url
									)
								"
								class="buy-tickets"
							>
								<img src="/static/icons/ticket.svg" alt="" />
								{{ btnText(e.minPrice).str }}
							</button>
						</div>
						<mobile-event-component v-else :key="kk" :data="e" />
					</template>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
import MobileEventComponent from "./MobileEventComponent.vue";
import { http } from "../components/services/httpService";
import parseDateMixin from "./parseDateMixin";
export default {
	mixins: [parseDateMixin],
	components: { MobileEventComponent },
	props: {
		isMobile: {
			default: false,
		},
	},
	data() {
		return {
			listType: true,
			months: [
				{
					month: "",
					year: 0,
					events: [],
				},
			],
		};
	},
	methods: {
		getPoster() {
			http.get(
				`/api/events/playbill?lang=${this.$i18n.locale}&target=playbill`
			).then((response) => {
				if (response.status < 400) {
					this.months = response.data.eventGroups.map(
						(m) =>
							(m = {
								...m,
								events: m.events.map((e) => ({
									...e,
									date: this.parseDate(e.date),
								})),
							})
					);
				}
			});
		},
		ticketsWidgetCall(widget, sold, url) {
			if (sold) this.$router.push(url);
			else {
				if (new RegExp("^https?:\/\/[^.]+\.[^.]+.+$").test(widget)) {
					document.location.href = widget;
				} else {
					eval(widget);
				}
			}
		},
	},
	watch: {
		"$route.params"() {
			document.documentElement.scrollTop = 0;
		},
		"$route.name"(newVal) {
			if (newVal === "poster") {
				document.title = this.$t("poster");
			}
		},
		"$i18n.locale": function() {
			this.getPoster();
		},
	},
	mounted() {
		this.getPoster();
		document.title = this.$t("poster");
		document.documentElement.scrollTop = 0;
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/poster.scss";
</style>
