import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: "ru-RU",
	fallbackLocale: "ru-RU",
	messages: {
		"ru-RU": {
			poster: "АФИША",
			main: "ГЛАВНАЯ",
			contacts: "КОНТАКТЫ",
			gallery: "ГАЛЕРЕЯ",
			presentation: "Презентация",
			seeNext: "Весь год",
			newModel: "НОВАЯ МОДЕЛЬ\nПРОВЕДЕНИЯ МЕРОПРИЯТИЙ",
			typeView1: "СПИСКОМ",
			typeView2: "ПЛИТКОЙ",
			artists: "АРТИСТЫ",
			buyText: "Билеты от ",
			checkPhoto: "Смотреть фотоотчёт",
			tickets: "БИЛЕТЫ",
			photos: "ФОТО С ВЫСТУПЛЕНИЙ",
			photosPast: "ПРИЗРАК ЭМОЦИЙ ПРОШЛОГО",
			about: "О ПРОСТРАНСТВЕ",
			similarEvents: "ПОХОЖИЕ МЕРОПРИЯТИЯ",
			openInMaps: "Открыть в картах",
			faq: "По всем вопросам",
			partnersFor: "Для партнёров и артистов",
			vip: "VIP-резерв в Telegram:",
			rent: "Аренда площадки для корпоративных клиентов",
			passedEvents: "ПРОШЕДШЕE",
			passed: "Прошедшее",
			msk: "МОСКВА",
			aboutUs: "О нас",
			eGroup: "Группы события",
			openSite: "Открыть сайт",
			spb: "САНКТ-ПЕТЕРБУРГ",
		},
		"en-US": {
			poster: "EVENTS",
			main: "MAIN",
			contacts: "CONTACTS",
			gallery: "GALLERY",
			presentation: "Presentation",
			seeNext: "Show for the whole year",
			newModel: "NEW EVENT MODEL",
			typeView1: "LIST",
			typeView2: "TILE",
			artists: "ARTISTS",
			buyText: "Tickets from ",
			checkPhoto: "View the photo report",
			tickets: "TICKETS",
			photos: "EMOTIONS",
			photosPast: "THE GHOST OF THE EMOTIONS OF THE PAST",
			about: "ABOUT SPACE",
			similarEvents: "SIMILAR EVENTS",
			openInMaps: "Open in maps",
			faq: "For all questions",
			vip: "VIP reserve in Telegram:",
			partnersFor: "For partners and artists",
			rent: "Site rental for corporate clients",
			passedEvents: "Past events",
			passed: "Past",
			openSite: "Open site",
			msk: "MOSCOW",
			aboutUs: "About",
			eGroup: "Event groups",
			spb: "SAINT-PETERSBURG",
		},
	},
});
