<template>
	<section :class="{ 'menu-active': openMobileMenu, fill: fill }">
		<div class="top-info">
			<router-link to="/" class="logo">
				<img
					@click="openMobileMenu = false"
					src="/static/icons/placebo25.svg"
					alt="placebo25 иконка" />
			</router-link>
			<div
				@click="openMobileMenu = !openMobileMenu"
				class="mobile-menu"></div>
		</div>
		<nav>
			<ul class="links">
				<li @click="openMobileMenu = false">
					<router-link to="/poster">{{ $t("poster") }}</router-link>
				</li>
				<li @click="openMobileMenu = false">
					<router-link to="/passedEvents">{{ $t("passed") }}</router-link>
				</li>
				<li @click="openMobileMenu = false">
					<router-link to="/gallery">{{ $t("gallery") }}</router-link>
				</li>
				<li @click="openMobileMenu = false">
					<router-link to="/contacts">{{ $t("contacts") }}</router-link>
				</li>
				<li @click="openMobileMenu = false">
					<router-link to="/#about">{{ $t("aboutUs") }}</router-link>
				</li>
			</ul>
		</nav>
		<div class="bottom-info">
			<div class="social-networks">
				<ul>
					<li>
						<a :href="sideInfo.socials.instagram">
							<img
								src="/static/icons/instagram.svg"
								alt="ссылка на инстаграм"
							/>
						</a>
					</li>
					<li>
						<a :href="sideInfo.socials.facebook">
							<img
								src="/static/icons/facebook.svg"
								alt="ссылка на фейсбук"
							/>
						</a>
					</li>
					<li>
						<a :href="sideInfo.socials.messenger">
							<img src="/static/icons/messenger.svg" alt="ссылка на messenger" />
						</a>
					</li>
          <li>
						<a :href="sideInfo.socials.email">
							<img src="/static/icons/email.svg" alt="email" />
						</a>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import { http } from "./services/httpService";
export default {
	data() {
		return {
			ruLang: true,
			openMobileMenu: false,
			fill: false,
			sideInfo: {
				address: "",
				email: "",
				socials: {
					vk: "",
					instagram: "",
					facebook: "",
					telegram: "",
          messenger: "",
          email: "",
				},
			},
		};
	},
	methods: {
		setLocale(locale) {
			this.ruLang = locale === "ru-RU";
			this.$i18n.locale = locale;
			this.getSideInfo();
			localStorage.setItem("locale", locale);
		},
		getSideInfo() {
			http.get(`/api/contacts/sidebar?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.sideInfo = response.data;
						const t = response.data.socials.telegram.split("@")[1];
						this.sideInfo.socials.telegram = t;
					}
				}
			);
		},
	},
	mounted() {
		const locale = "en-US"; // localStorage.getItem("locale");
		this.setLocale(locale ? locale : "ru-RU");
		this.getSideInfo();
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

section {
	position: fixed;
	display: grid;
	gap: ptr(45px);
	left: ptr(30px);
	top: ptr(39px);
	width: ptr(190px);
	height: 90%;
	grid-template-rows: min-content 1fr min-content;
	z-index: 2;
	&::after {
		content: "";
		position: absolute;
		right: 0;
		width: ptr(1px);
		background: rgba(255, 255, 255, 0.13);
		height: 100%;
	}
}

.top-info {
	display: flex;
	align-items: flex-start;
	.logo {
		img {
			width: ptr(93px);
			transition: $trs;
			&:hover {
				filter: brightness(0.8);
			}
		}
		margin-right: ptr(40px);
	}
}
.language-select {
	display: flex;
}
.mobile {
	display: none;
}
.selector {
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.1);
	color: white;
	transition: $trs;
	width: ptr(25px);
	height: ptr(25px);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ptr(11px);
	font-weight: 500;
	cursor: pointer;
	p {
		transform: translateY(ptr(1px));
		margin: 0;
	}
	&:nth-child(1) {
		margin-right: ptr(3.5px);
	}
	&:hover {
		background: white;
		color: black;
	}
}

.active {
	opacity: 1;
	color: black;
	background: white;
}

.links {
	display: grid;
	gap: ptr(3px);
	padding: 0;
	margin: 0;
	li {
		transition: 0.4s ease-out;
		&:hover {
			transform: translateX(ptr(4px));
		}
		a {
			font-size: ptr(16px);
			font-weight: 500;
			text-transform: uppercase;
		}
	}
	.loft-hall {
		margin-top: ptr(30px);
		img {
			margin-left: ptr(3px);
			opacity: .6;
			width: ptr(9px);
			transform: translateY(-1.9px);
		}
	}
}

.adress {
	margin: 0 ptr(10px) ptr(10px) 0;
	font-size: ptr(14px);
	text-transform: uppercase;
	line-height: ptr(20px);
}

.msk {
	opacity: 0.5;
	font-size: ptr(14px);
	line-height: ptr(20px);
	position: relative;
	cursor: pointer;
	width: fit-content;
	&::after {
		content: "Coming in 2021";
		display: flex;
		justify-content: center;
		padding-top: ptr(7px);
		box-sizing: border-box;
		background-image: url("/static/icons/cmn.svg");
		background-size: cover;
		width: ptr(110px);
		height: ptr(36px);
		position: absolute;
		opacity: 0;
		transition: $trs;
		pointer-events: none;
		top: ptr(-40px);
	}
	&:hover::after {
		opacity: 1;
	}
}

.href {
	color: $sub-color;
	text-transform: uppercase;
	font-size: ptr(14px);
	font-family: sans-serif;
}

.social-networks ul {
	display: grid;
	gap: ptr(9.5px);
	margin: ptr(24px) 0 0;
	grid-template-columns: repeat(4, min-content);
	li {
		a {
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.1);
			display: flex;
			height: ptr(30px);
			width: ptr(30px);
			align-items: center;
			justify-content: center;
			transition: $trs;
			&:hover {
				background: $sub-color;
				img {
					filter: invert(1);
				}
			}
		}
		.tlg {
			img {
				width: ptr(13px);
				transform: translateX(ptr(-1px));
				filter: none;
			}
			&:hover img {
				filter: invert(0);
			}
		}
		img {
			transition: 0.2s ease-out;
			filter: invert(1);
			width: ptr(10px);
		}
		&:nth-child(2) {
			img {
				width: ptr(6.3px);
			}
		}
		&:nth-child(3) {
			img {
				width: ptr(13px);
			}
		}
	}
}

@media (max-width: 1024px) {
	section {
		width: 100%;
		z-index: 2;
		left: 0;
		top: 0;
		padding: ptr(12px) ptr(12px) ptr(39px);
		height: ptr(78px);
		box-sizing: border-box;
		overflow: hidden;
		transition: $trs, background 0.2s 0.4s;
		grid-template-rows: min-content 1fr min-content min-content;
		gap: ptr(0px);
		pointer-events: none;
		&::after {
			// 	justify-self: center;
			// 	transition: $trs;
			content: none;
			// 	position: absolute;
			// 	right: unset;
			// 	bottom: 0px;
			// 	width: calc((100% - 20rem / 16));
			// 	background: #ffffff;
			// 	height: ptr(1px);
			// 	transition: $trs;
		}
	}
	.fill {
		transition: $trs, background $trs;
		transform: translateY(ptr(-100px));
		// &::after {
		// 	width: 100%;
		// }
	}
	.top-info {
		height: ptr(85px);
		justify-content: space-between;
		.logo {
			position: relative;
			transition: $trs;
			left: ptr(125px);
			top: ptr(0px);
			opacity: 0;
			pointer-events: none;
			margin: 0;
			img {
				width: ptr(39px);
			}
		}
	}
	.mobile-menu {
		width: ptr(37px);
		height: ptr(57px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		pointer-events: all;
		transition: $trs;
		&::after,
		&::before {
			content: "";
			transition: $trs;
			width: 100%;
			height: ptr(2px);
			background: white;
			position: relative;
			transform-origin: top left;
		}
		&::after {
			transform-origin: top right;
			top: ptr(6px);
		}
	}
	nav {
		margin: ptr(30px) 0;
		max-height: ptr(280px);
		display: flex;
		justify-content: center;
		transition: 0.3s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		opacity: 0;
	}
	.links {
		gap: ptr(10px);
		li {
			opacity: 0.8;
			text-align: center;
			&:hover {
				transform: unset;
			}
			a {
				font-size: ptr(20px);
				font-weight: 100;
			}
		}
		.loft-hall {
			margin-top: ptr(20px);
			img {
				position: absolute;
				width: ptr(11px);
				opacity: .5;
				transform: translate3d(ptr(4px));
			}
		}
	}
	.bottom-info {
		display: grid;
		gap: ptr(26px);
		transition: 0.3s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		opacity: 0;
	}
	.social-networks {
		grid-row: 1;
		justify-self: center;
		ul {
			margin: 0;
			li {
				a {
					height: ptr(32px);
					width: ptr(32px);
				}
				img {
					width: ptr(11.25px);
				}
				&:nth-child(2) {
					img {
						width: ptr(6.93px);
					}
				}
				&:nth-child(3) {
					img {
						width: ptr(14.2px);
					}
				}
			}
		}
	}
	.href {
		justify-self: center;
		border-radius: ptr(100px);
		color: $text-color;
		font-family: $main-font;
		font-size: ptr(16px);
		font-weight: 100;
		text-transform: unset;
		padding: ptr(2px) ptr(9px);
		border: 1px solid #464646;
	}
	.desktop,
	.adress {
		display: none;
	}
	.mobile {
		display: flex;
	}
	.language-select {
		position: absolute;
		justify-self: center;
		transition: 0.3s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		left: ptr(15px);
		top: ptr(24px);
		opacity: 0;
	}
	.selector {
		&:hover {
			background: rgb(49, 49, 49);
			color: white;
			border: 1px solid rgb(49, 49, 49);
		}
	}
	.active {
		background: rgb(49, 49, 49);
		color: white;
		border: 1px solid rgb(49, 49, 49);
	}
	.selector:hover {
		opacity: 1;
	}
	.menu-active {
		height: 100%;
		transition: $trs, background 0.2s;
		background: black;
		pointer-events: all;
		z-index: 5;
		nav,
		.bottom-info,
		.mobile {
			opacity: 1;
		}
		.logo {
			position: relative;
			left: ptr(125px);
			top: ptr(15px);
			opacity: 1;
			pointer-events: all;
			img {
				width: ptr(50px);
			}
		}
		&::after {
			background: rgba(255, 255, 255, 0);
		}
		.mobile-menu {
			width: ptr(27.58px);
			height: ptr(27.58px);
			&::before {
				transform: translateX(ptr(4.7px)) rotate(45deg);
			}
			&::after {
				top: ptr(-2px);
				transform: translateX(ptr(-4.7px)) rotate(-45deg);
			}
		}
	}
}
</style>
