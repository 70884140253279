<template>
	<div class="gallery-container">
		<div
			class="multiple-corousel"
			:style="{
				transform: `translate3d(${-gallery.x}px,0,0)`,
			}"
		>
			<div v-if="video.length > 0" class="media-container video">
				<iframe
					:src="
						`https://www.youtube.com/embed/${parseYoutubeUrl}?modestbranding=1&autohide=1&showinfo=0`
					"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
			<template v-for="(m, k) in images">
				<div
					:key="k"
					class="media-container image"
					:class="{ inactive: k !== gallery.pos }"
				>
					<img :src="m" alt="" />
				</div>
			</template>
		</div>
		<div class="arrow-container">
			<div
				v-if="grlPage || gallery.pos !== 0"
				@click="moveMedia(-1)"
				class="arrow left"
			>
				<svg viewBox="0 0 99 84" fill="none">
					<path
						d="M56.7998 0L98.7998 42L56.7998 84H53.0198L93.7598 43.26H0.799805V40.74H93.7598L53.0198 0H56.7998Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				v-if="
					(gallery.pos !== images.length - 2 && images.length > 1) ||
						grlPage
				"
				@click="moveMedia(1)"
				class="arrow right"
			>
				<svg viewBox="0 0 99 84" fill="none">
					<path
						d="M56.7998 0L98.7998 42L56.7998 84H53.0198L93.7598 43.26H0.799805V40.74H93.7598L53.0198 0H56.7998Z"
						fill="white"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		video: {
			default: "",
		},
		images: {
			default: [],
		},
		grlPage: {
			default: false,
		},
	},
	data() {
		return {
			gallery: {
				x: 0,
				pos: 0,
				container: null,
			},
		};
	},
	methods: {
		moveMedia(dir) {
			if (this.gallery.container === null) {
				this.gallery.container = this.$el.querySelector(
					".multiple-corousel"
				);
			}
			if (this.grlPage) {
				let newPos = this.gallery.pos + dir;
				if (newPos === -1) {
					newPos = this.images.length - 1;
				}
				this.gallery.pos = newPos % this.images.length;
			} else if (this.gallery.pos + dir < this.images.length + 1) {
				this.gallery.pos += dir;
			}
			this.gallery.x = this.gallery.container.children[
				this.gallery.pos
			].offsetLeft;
			this.$emit("changePage", this.gallery.pos + 1);
		},
	},
	computed: {
		parseYoutubeUrl() {
			const url = this.video.split("=");
			let src = url[1];
			if (url.length > 2) {
				src = src.split("&")[0];
				src += "?start=" + url[2];
			}
			return src;
		},
	},
};
</script>

<style lang="scss">
.ytp-show-cards-title,
.ytp-chrome-top {
	display: none !important;
}
</style>

<style lang="scss" scoped>
@import "./styles/config.scss";
.gallery-container {
	margin-top: ptr(90px);
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	width: ptr(1640px);
}

.multiple-corousel {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	padding-bottom: ptr(20px);
	gap: ptr(25px);
	transition: $trs;
	position: relative;
	&::-webkit-scrollbar {
		display: none;
	}
}
.arrow-container {
	position: absolute;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	align-self: center;
}
.arrow {
	cursor: pointer;
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	padding: 0 ptr(56px);
	right: 0;
	&::after {
		position: absolute;
		content: "";
		height: ptr(400px);
		width: ptr(520px);
		pointer-events: none;
		background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.322));
		transform: rotate(-90deg);
		right: ptr(-100px);
	}
	svg {
		position: relative;
		z-index: 2;
		width: ptr(70px);
	}
}
.left {
	right: unset;
	left: 0;
	&::after {
		transform: rotate(90deg);
		right: unset;
		left: ptr(-100px);
	}
	svg {
		transform: rotate(180deg);
	}
}
.media-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #1a1a1a;
	height: ptr(492px);
	user-select: none;
}
.image {
	width: ptr(454px);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.video {
	width: ptr(768px);
	iframe {
		width: 100%;
		height: 100%;
	}
}
@media (max-width: 1024px) {
	.gallery-container {
		margin-top: ptr(60px);
		overflow: scroll;
		width: 100%;
		.arrow-container {
			display: none;
		}
	}
	.multiple-corousel {
		margin-top: ptr(0px);
		gap: ptr(10px);
		width: 100%;
	}
	.media-container {
		height: ptr(177px);
	}
	.video {
		width: ptr(255px);
	}
	.image {
		width: ptr(177px);
	}
}
</style>
