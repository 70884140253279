<template>
	<section class="page">
		<picture v-if="mainEvents.length > 0">
			<source
				srcset="/static/img/main-back.jpg"
				media="(max-width: 1024px)"
			/>
			<source srcset="/static/img/main-back.png" />
			<img src="/static/img/main-back.png" alt="" class="fixed-img" />
		</picture>
		<div v-if="mainEvents.length > 0" class="main-events">
			<template v-for="(e, k) in mainEvents">
				<div
					v-if="!isMobile"
					:key="k"
					@mouseleave="hover($event, false)"
					class="top-event"
				>
					<div
						class="main-group"
						@mouseenter="hover($event, true, e.name.length > 18)"
					>
						<router-link :to="e.url" class="moving-name">
							<div class="main-artist">
								{{ e.name }}
							</div>
							<div v-if="e.name.length > 18" class="main-artist">
								{{ e.name }}
							</div>
						</router-link>
						<div
							v-if="e.sticker"
							class="sticker"
							:style="{
								top: e.sticker.offsetTop / 16 + 'rem',
								left: e.sticker.offsetLeft / 16 + 'rem',
								background: e.sticker.color,
							}"
						>
							{{ e.sticker.message }}
						</div>
						<svg viewBox="0 0 99 84" fill="none">
							<path
								d="M56.7998 0L98.7998 42L56.7998 84H53.0198L93.7598 43.26H0.799805V40.74H93.7598L53.0198 0H56.7998Z"
								fill="white"
							/>
						</svg>
					</div>
					<div class="hover-group">
						<div class="description">
							{{ e.summary }}
						</div>
						<div class="addable-hover-info">
							<div
								class="date-container"
								:class="{ tba: e.date.str === 'TBA' }"
							>
								<div class="date">
									{{ e.date.str }}
								</div>
								<div class="weekday">
									{{ e.date.minWeek }}
								</div>
							</div>
							<button
								@click="ticketsWidgetCall(e.ticketsWidget)"
								:disabled="btnText(e.minPrice).sold"
								class="buy-tickets main-t"
							>
								<img src="/static/icons/ticket.svg" alt="" />
								{{ btnText(e.minPrice).str }}
							</button>
							<div class="age">{{ e.minAge }}+</div>
						</div>
					</div>
				</div>
				<mobile-event-component v-else :key="k" :data="e" />
			</template>
		</div>
		<div v-else class="thats-all-for-now">
			<img :src="noEvents.file" alt="" />
			<div class="no-more">
				{{ noEvents.title }}
			</div>
		</div>
		<div class="months-poster">
			<div class="next-months">
				<div
					v-for="(m, k) in parsedMonths.names"
					:key="k"
					@click="setMonth(k, m)"
					:class="{
						chosen: lineSettings.month === m,
					}"
					class="month"
					:id="`${k}-m`"
				>
					{{ m }}
				</div>
				<div
					class="active-line"
					:style="{
						left: lineSettings.offset + 'px',
						width: lineSettings.width + 'px',
					}"
				></div>
				<router-link to="/poster" class="check-next-year">
					{{ $t("seeNext") }} →
				</router-link>
			</div>
			<div class="months-events">
				<template v-for="(e, k) in parsedMonths.events">
					<div v-if="!isMobile" :key="k" class="event-container">
						<div class="event-data">
							<div class="number">
								{{ e.date.mDate }}
							</div>
							<span>
								{{ e.date.maxWeek }}
							</span>
						</div>
						<div class="event-info">
							<div class="tags">
								<div
									v-for="(t, tt) in e.tags"
									:key="tt"
									class="tag"
								>
									{{ t }}
								</div>
							</div>
							<router-link :to="`${e.url}`" class="artist-name">
								{{ e.name }}
							</router-link>
							<div class="event-description">
								{{ e.summary }}
							</div>
						</div>
						<div class="event-age">{{ e.minAge }}+</div>
						<button
							@click="ticketsWidgetCall(e.ticketsWidget)"
							:disabled="btnText(e.minPrice).sold"
							class="buy-tickets"
						>
							<img src="/static/icons/ticket.svg" alt="" />
							<p>
								{{ btnText(e.minPrice).str }}
							</p>
						</button>
					</div>
					<mobile-event-component v-else :data="e" :key="k" />
				</template>
			</div>
		</div>
		<div class="event-place-description">
			<h1 id="about">
				{{ $t("newModel") }}
			</h1>
			<slider-component :mass="placeDescriptions" :fullMode="false">
				<template v-slot="content">
					<div class="scroll-description">
						{{ content.content.description }}
						<div v-if="content.content.socials" class="socials-in">
							<a
								class="site"
								v-if="content.content.socials.site"
								:href="content.content.socials.site"
							>
								{{ $t("openSite") }}
							</a>
							<a
								v-if="content.content.socials.facebook"
								:href="content.content.socials.facebook"
							>
								<img
									src="/static/icons/facebook.svg"
									alt="ссылка на facebook"
								/>
							</a>
							<a
								v-if="content.content.socials.instagram"
								:href="content.content.socials.instagram"
							>
								<img
									src="/static/icons/instagram.svg"
									alt="ссылка на instagram"
								/>
							</a>
						</div>
					</div>
					<div class="img-container">
						<img :src="content.content.image" alt="" />
					</div>
				</template>
			</slider-component>
		</div>
	</section>
</template>

<script>
import SliderComponent from "../components/SliderComponent.vue";
import MobileEventComponent from "./MobileEventComponent.vue";
import { http } from "../components/services/httpService";
import parseDateMixin from "./parseDateMixin";
export default {
	mixins: [parseDateMixin],
	components: { SliderComponent, MobileEventComponent },
	props: {
		isMobile: {
			default: false,
		},
	},
	data() {
		return {
			mainEvents: [],
			months: [],
			chosenMonth: 0,
			placeDescriptions: [],
			noEvents: "НОВЫЙ СЕЗОН ПОСЛЕ ОГРАНИЧЕНИЙ `21",
			lineSettings: {
				active: "",
				offset: 0,
				width: 0,
			},
			interval: null,
		};
	},
	methods: {
		hover(e, on = true, startMoving = false) {
			const t = on ? e.target.closest(".top-event") : e.target;
			const hGroup = t.querySelector(".hover-group");
			const n = t.querySelector(".moving-name");
			if (on) {
				if (t.classList.contains("hovered")) {
					return;
				}
				t.classList.add("hovered");
				hGroup.style.height =
					t.querySelector(".hover-group").scrollHeight + "px";
			} else if (!on) {
				t.classList.remove("hovered");
				hGroup.style.height = "0px";
				clearInterval(this.interval);
				this.interval = null;
				n.style.transform = "translate3d(0px,0,0)";
				n.style.transition = ".4s cubic-bezier(0.25,0.74,0.22,0.99)";
			}
			if (startMoving) {
				let ch = 0;
				t.classList.add("overflow-move");
				n.style.transition = ".0s cubic-bezier(0.25,0.74,0.22,0.99)";
				const w = n.firstChild.scrollWidth;
				this.interval = setInterval(() => {
					if (w === ch + 10) {
						ch = -10;
					} else {
						ch += 1;
					}
					n.style.transform = `translate3d(${-ch}px,0,0)`;
				}, 5);
			}
		},
		ticketsWidgetCall(str) {
			if (new RegExp("^https?:\/\/[^.]+\.[^.]+.+$").test(str)) {
				document.location.href = str;
			} else {
				eval(str);
			}
		},
		setMonth(k, name) {
			const el = document.getElementById(`${k}-m`);
			this.chosenMonth = k;
			this.lineSettings = {
				month: name,
				offset: el.offsetLeft,
				width: el.scrollWidth,
			};
		},
		getMainEvents() {
			http.get(`/api/events/main?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.mainEvents = response.data.events.map((e) => ({
							...e,
							date: this.parseDate(e.date),
						}));
					}
				}
			);
		},
		getPoster() {
			http.get(
				`/api/events/playbill?lang=${this.$i18n.locale}&target=main`
			).then((response) => {
				if (response.status < 400) {
					this.months = response.data.eventGroups.map(
						(m) =>
							(m = {
								...m,
								events: m.events.map((e) => ({
									...e,
									date: this.parseDate(e.date),
								})),
							})
					);
					setTimeout(() => {
						this.setMonth(0, this.parsedMonths.names[0]);
					}, 0);
				}
			});
		},
		getNoEventsInfo() {
			http.get(`/api/page-placeholders?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.noEvents = response.data;
					}
				}
			);
		},
		getEventModals() {
			http.get(
				`/api/event-models?lang=${this.$i18n.locale}&target=main`
			).then((response) => {
				if (response.status < 400) {
					this.placeDescriptions = response.data.models;
				}
			});
		},
	},
	computed: {
		parsedMonths() {
			const m = this.months.slice(0, this.isMobile ? 1 : 3);
			const e = m.length > 0 ? m[this.chosenMonth].events : [];
			return {
				names: m.map((e) => e.month),
				events: e,
			};
		},
	},
	watch: {
		"$i18n.locale": function() {
			this.getMainEvents();
			this.getPoster();
			this.getNoEventsInfo();
			this.getEventModals();
		},
		"$route.hash": function() {
			if (this.$route.hash === "#about") {
				setTimeout(() => {
					document.getElementById("about").scrollIntoView();
				}, 0);
			}
		},
		"$route.name"(newVal) {
			if (newVal === "Main") {
				document.title = "Temple of Deer";
			}
		},
	},
	mounted() {
		this.getMainEvents();
		this.getPoster();
		this.getEventModals();
		this.getNoEventsInfo();
		document.title = "Temple of Deer";
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	position: relative;
}

.fixed-img {
	top: ptr(-220px);
	left: ptr(-660px);
	height: 100%;
}

.main-events {
	display: grid;
	grid-template-rows: repeat(5, max-content);
	min-height: ptr(1050px);
	gap: ptr(5px);
}
.thats-all-for-now {
	height: ptr(1050px);
	position: relative;
	img {
		top: ptr(-10px);
		left: ptr(-270px);
		position: absolute;
		width: 100vw;
		z-index: -1;
		height: 100%;
		object-fit: cover;
	}
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 50%;
		left: ptr(-270px);
		bottom: 0;
		background: linear-gradient(rgba(0, 0, 0, 0), black);
	}
}
.no-more {
	font-size: ptr(140px);
	font-weight: 100;
}
.top-event {
	display: grid;
}
.hover-group {
	overflow: hidden;
	height: 0;
	opacity: 0;
	transition: $trs 0.15s;
}
.main-group {
	align-self: center;
	display: inline-flex;
	width: fit-content;
	align-items: center;
	position: relative;
	svg {
		pointer-events: none;
		width: ptr(100px);
		height: ptr(84px);
		margin-left: ptr(50px);
		transform: translateX(-50px);
		margin-top: ptr(-8px);
		opacity: 0;
		transition: $trs;
	}
}
.moving-name {
	display: flex;
	align-items: center;
	overflow: hidden;
	margin: 0 0 0 ptr(-10px);
	height: ptr(160px);
	transition: $trs;
}
.main-artist {
	font-size: ptr(140px);
	font-weight: 100;
	text-transform: uppercase;
	white-space: nowrap;
	padding-right: ptr(60px);
}
.sticker {
	width: ptr(112px);
	height: ptr(112px);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	opacity: 0.9;
	text-transform: uppercase;
	font-size: ptr(10px);
	line-height: ptr(13px);
	position: absolute;
	pointer-events: none;
	background: $sub-color;
	transform: rotate(-20deg);
}

.description {
	font-size: ptr(18px);
	font-weight: 300;
}
.next-months {
	display: grid;
	grid-template-columns: repeat(3, max-content) auto;
	gap: ptr(67px);
	position: relative;
	font-size: ptr(30px);
	font-weight: 100;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: ptr(1px);
		background: rgba(255, 255, 255, 0.21);
	}
}
.month {
	cursor: pointer;
	padding-bottom: ptr(30px);
	opacity: 0.5;
	text-transform: capitalize;
	transition: $trs;
}
.active-line {
	background: white;
	height: ptr(3px);
	position: absolute;
	transition: $trs;
	bottom: 0;
}
.check-next-year {
	justify-self: flex-end;
	cursor: pointer;
	grid-column: 4;
}
.months-poster {
	padding-right: ptr(76px);
}
.months-events {
	display: grid;
}
.event-place-description {
	margin-right: ptr(76px);
	h1 {
		font-size: ptr(74px);
		margin: ptr(208px) 0 ptr(80px);
		font-weight: 100;
		padding: ptr(25px) 0 0;
		white-space: pre-line;
		text-transform: uppercase;
	}
	article {
		padding-bottom: ptr(150px);
	}
}

.img-container {
	width: ptr(1022px);
	height: ptr(513px);
	overflow: hidden;
	background: rgb(41, 41, 41);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.scroll-description {
	white-space: pre-line;
	transition: $trs;
	line-height: ptr(30px);
}
.overflow-move {
	overflow: hidden;
}
.hovered {
	.hover-group {
		opacity: 1;
	}
	.main-group svg {
		opacity: 1;
		transform: translateX(0px);
	}
	.top-mobile-group svg {
		opacity: 1;
		transform: rotate(-90deg);
	}
	.mobile-hover-group {
		opacity: 1;
	}
}

@media (max-width: 1024px) {
	.main-events {
		display: grid;
		grid-auto-rows: max-content;
		height: unset;
		min-height: unset;
		margin-bottom: ptr(101px);
	}
	.fixed-img {
		width: ptr(2000px);
	}
	.months-poster {
		padding: 0;
	}
	.thats-all-for-now {
		padding: ptr(20px) ptr(10px);
		img {
			top: ptr(-90px);
			left: 0;
		}
		&::after {
			left: 0;
		}
	}
	.no-more {
		font-size: ptr(60px);
	}
	.next-months {
		grid-template-columns: max-content 1fr;
		padding: ptr(10px);
		gap: 0;
		&::after {
			left: ptr(10px);
			width: calc(100% - 20rem / 16);
		}
	}
	.month {
		font-size: ptr(16px);
		padding-bottom: ptr(9px);
	}
	.check-next-year {
		font-size: ptr(13px);
	}
	.event-place-description {
		padding: 0 ptr(10px);
		margin: 0;
		h1 {
			font-size: ptr(33px);
			line-height: ptr(42px);
			width: ptr(200px);
			margin: ptr(70px) 0 ptr(32px);
		}
		article {
			padding-bottom: ptr(50px);
		}
	}
	.inactive .scroll-description {
		opacity: 0;
	}
	.scroll-description {
		font-size: ptr(12px);
		line-height: ptr(18px);
		font-weight: 300;
	}
	.img-container {
		width: ptr(254px);
		grid-row: 1;
		height: ptr(133px);
	}
}
</style>
